import React, { forwardRef, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ButtonBase from "@mui/material/ButtonBase";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useNavigate, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import * as Api from "../../api";

import MenuItem from "./MenuItem";
import MenuListItem from "./MenuListItem";
import AlertMsg from "../AlertMsg";
import ConfirmAlert from "./ConfirmAlert";

import { ReactComponent as RightArrow } from "../../assets/images/right_arrow.svg";
import { ReactComponent as LeftDarkArrow } from "../../assets/images/left_dark_arrow.svg";
import { ReactComponent as GridViewIcon } from "../../assets/images/grid_view.svg";
import { ReactComponent as GridViewActiveIcon } from "../../assets/images/grid_view_active.svg";
import { ReactComponent as ListViewIcon } from "../../assets/images/list_view.svg";
import { ReactComponent as ListViewActiveIcon } from "../../assets/images/list_view_active.svg";

import { LOADING_COLOR, THEME_COLOR, THEME_COLOR5 } from "../../constants";

import "../../assets/css/custom.css";

const theme = createTheme({
  palette: {
    loading_color: {
      main: LOADING_COLOR,
    },
  },
});

let menu_page_size = 30000;
let menu_list_height = window.innerHeight - 250;

const CategoryMenuList = (props) => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [menuCategoryList, setMenuCategoryList] = useState([]);
  const [listType, setListType] = useState("grid");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [menuItemData, setMenuItemData] = useState(null);
  const [menuOrderLoading, setMenuOrderLoading] = useState(false);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const menuScrollRef = useRef([]);

  useEffect(() => {
    if (props?.selectedMenuItems?.length) {
      setSelectedMenuItems(props.selectedMenuItems);
    } else {
      setSelectedMenuItems([]);
    }
  }, [props?.selectedMenuItems]);

  useEffect(() => {
    if (menuCategoryList.length) {
      let category_id = hash.split("#").join("");
      if (category_id == "") {
        category_id = menuCategoryList[0]._id;
      }
      setCategory(category_id);
      menuScrollRef.current = menuCategoryList.map((_, i) =>
        document.getElementById("CategoryId" + i)
          ? { current: document.getElementById("CategoryId" + i) }
          : React.createRef()
      );
      if (menuScrollRef.current.length == menuCategoryList.length) {
        let menu_height =
          menuScrollRef.current[menuScrollRef.current.length - 1].current
            ?.clientHeight;
        if (menu_height < menu_list_height) {
          if (document.getElementById("additionalHeight")) {
            document.getElementById("additionalHeight").style.height = `${
              menu_list_height - menu_height
            }px`;
          }
        }
        if (document.getElementById("categoryName" + category_id)) {
          document.getElementById("categoryName" + category_id).style.position =
            "sticky";
        }
      }
      let cat_list = [...menuCategoryList];
      let find_index = cat_list.findIndex((x) => x._id == category_id);
      if (find_index >= 0) {
        let el = document.getElementById("mainViewId");
        if (el) {
          el.scrollTo(0, menuScrollRef.current[find_index]?.current?.offsetTop);
        }
      }
    }
  }, [menuCategoryList]);

  useEffect(() => {
    if (typeof props.categoryMenuList != "undefined") {
      setLoading(false);
      setMenuCategoryList(props.categoryMenuList);
    } else {
      getCategoryList();
    }
  }, [props.categoryMenuList]);

  const onSelectCategory = (data, index) => {
    setCategory(data._id);
    let cat_list = [...menuCategoryList];
    let find_index = cat_list.findIndex((x) => x._id == data._id);
    if (find_index >= 0) {
      menuCategoryList.map((x, i) => {
        if (x._id == data._id) {
          document.getElementById("categoryName" + x._id).style.position =
            "sticky";
        } else {
          document.getElementById("categoryName" + x._id).style.position =
            "relative";
        }
      });
      if (menuScrollRef.current[find_index]?.current) {
        let el = document.getElementById("mainViewId");
        if (el) {
          el.scrollTo(0, menuScrollRef.current[find_index]?.current?.offsetTop);
        }
      } else {
        menuScrollRef.current = menuCategoryList.map(
          (_, i) => menuScrollRef.current[i] ?? React.createRef()
        );
      }
    }
  };

  const selectListType = (type) => {
    setMenuOrderLoading(true);
    setListType(type);
    setTimeout(() => {
      let cat_list = [...menuCategoryList];
      let find_index = cat_list.findIndex((x) => x._id == category);
      if (find_index >= 0) {
        menuCategoryList.map((x, i) => {
          if (x._id == category) {
            document.getElementById("categoryName" + x._id).style.position =
              "sticky";
          } else {
            document.getElementById("categoryName" + x._id).style.position =
              "relative";
          }
        });
        let el = document.getElementById("mainViewId");
        if (el) {
          el.scrollTo(0, menuScrollRef.current[find_index]?.current?.offsetTop);
        }
      }
      setMenuOrderLoading(false);
    }, 500);
  };

  const getCategoryList = () => {
    let filter =
      "pageSize=100000&pageNum=1&filter__idRestaurant=" + props.restaurantId;
    Api.getMenuCategoryList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        if (data.length > 0) {
          getMenuList(data);
        } else {
          setLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const getMenuList = (cat_list) => {
    let filter =
      "?pageSize=" +
      menu_page_size +
      "&pageNum=1&filter__idRestaurant=" +
      props.restaurantId +
      "&orderBy=order&orderByDir=asc";
    Api.getMenuList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        let list = [];
        cat_list.forEach((x) => {
          let menu_list =
            props.page == "ArModelSelectMenu"
              ? data.filter((y) => y._idCategory == x._id && y.modelId == null)
              : data.filter((y) => y._idCategory == x._id);

          let cat_menu = {
            ...x,
            menu: menu_list,
          };
          list.push(cat_menu);
        });
        // if (props.page == "ArModelSelectMenu") {
        list = list.filter((y) => y.menu.length > 0);
        // }
        setMenuCategoryList(list);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const editMenu = (item) => {
    navigate("/restaurant/" + props.restaurantId + "/add_menu/" + item._id);
  };

  const deleteMenu = (item) => {
    setMenuItemData(item);
    setConfirmDelete(true);
  };

  const onCloseConfirmDelete = () => {
    setConfirmDelete(false);
    setMenuItemData(null);
  };

  const confirmDeleteMenu = () => {
    setBtnLoading(true);
    Api.deleteMenu(menuItemData._id).then((response) => {
      if (response.success) {
        setMsgAlert({
          open: true,
          message: "Deleted Successfully",
          msgType: "success",
        });
        let catList = [...menuCategoryList];
        let catFindIndex = catList.findIndex(
          (x) => x._id == menuItemData._idCategory
        );
        if (catFindIndex >= 0) {
          let itemIndex = catList[catFindIndex].menu.findIndex(
            (item) =>
              item._id === menuItemData._id &&
              item._idCategory == menuItemData._idCategory
          );
          if (itemIndex >= 0) {
            catList[catFindIndex].menu.splice(itemIndex, 1);
            setMenuCategoryList(catList);
          }
        }
        setConfirmDelete(false);
        setMenuItemData(null);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setBtnLoading(false);
    });
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onSortEnd = (oldIndex, newIndex, cat_id) => {
    let catList = [...menuCategoryList];
    let catFindIndex = catList.findIndex((x) => x._id == cat_id);
    if (catFindIndex >= 0) {
      let old_list = [...catList[catFindIndex].menu];
      let new_list = arrayMoveImmutable(
        catList[catFindIndex].menu,
        oldIndex,
        newIndex
      );

      let order_list = new_list.map((x, i) => {
        return { ...x, order: catList[catFindIndex].menu[i].order };
      });

      let data_list = order_list.map((x) => {
        return { order: x.order, _idMenuItem: x._id };
      });
      let data = JSON.stringify(data_list);

      catList[catFindIndex].menu = order_list;
      setMenuCategoryList(catList);
      setMenuOrderLoading(true);
      Api.menuRearrange(data).then((response) => {
        if (response.success) {
        } else {
          catList[catFindIndex].menu = old_list;
          setMenuCategoryList(catList);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setMenuOrderLoading(false);
      });
    }
  };

  const handleMainScroll = (e) => {
    if (e.target.scrollTop) {
      let filter_cat = menuScrollRef.current.filter(
        (x) =>
          x.current?.offsetTop + x.current?.clientHeight - 10 >=
          e.target.scrollTop
      );
      if (filter_cat.length > 0) {
        let cat_id = filter_cat[0].current?.attributes["cat-id"]["value"];
        if (category != cat_id) {
          menuCategoryList.map((x, i) => {
            if (x._id == cat_id) {
              document.getElementById("categoryName" + cat_id).style.position =
                "sticky";
            } else {
              document.getElementById("categoryName" + x._id).style.position =
                "relative";
            }
          });
          setCategory(cat_id);
        }
      }
    } else {
    }
  };

  useEffect(() => {
    if (selectedItem) {
      let list = [...selectedMenuItems];
      let index = list.findIndex((x) => x._id == selectedItem._id);
      if (index >= 0) {
        list = list.filter((x) => x._id != selectedItem._id);
      } else {
        list.push(selectedItem);
      }
      setSelectedItem(null);
      setSelectedMenuItems(list);
      props.onSelectMenu(list);
    }
  }, [selectedItem]);

  const onSelect = (item) => {
    if (props.page == "ArModelSelectMenu") {
      props.onSelectMenu(item);
    } else if (props.page == "AddModifierItemModal") {
      setSelectedItem(item);
    }
  };

  const MenuListView = React.useMemo(() => {
    return menuCategoryList.map((cat, index1) => {
      let filterMenu = cat.menu.filter(
        (x) =>
          x?.name?.toLowerCase()?.match(props.searchMenu?.toLowerCase()) ||
          x?.description?.toLowerCase()?.match(props.searchMenu?.toLowerCase())
      );
      if (filterMenu.length == 0) {
        return (
          <Grid
            key={index1}
            ref={menuScrollRef.current[index1]}
            id={"CategoryId" + index1}
            cat-id={cat._id}
          >
            <Box id={"categoryName" + cat._id} />
          </Grid>
        );
      }
      return (
        <Grid
          mb={2}
          px={1}
          key={index1}
          ref={menuScrollRef.current[index1]}
          id={"CategoryId" + index1}
          cat-id={cat._id}
        >
          <Typography sx={styles.menuTitleText} id={"categoryName" + cat._id}>
            {cat.name}
          </Typography>
          <Grid mt={1.5}>
            {filterMenu.length ? (
              <Grid>
                {listType == "grid" ? (
                  <Grid container direction="row">
                    {filterMenu.map((item, index) => {
                      let selected = false;
                      if (props?.inititalSelectedItems?.length) {
                        let findIndex = props.inititalSelectedItems.findIndex(
                          (x) => x._id == item._id
                        );
                        if (findIndex >= 0) {
                          selected = true;
                        }
                      }
                      return (
                        <MenuItem
                          page={props.page}
                          key={index}
                          firstItem={index % 4 === 0}
                          lastItem={(index + 1) % 4 === 0}
                          data={item}
                          selected={selected}
                          allowSelect={props.page == "AddModifierItemModal"}
                          showMenuPopup={props.showMenuPopup}
                          editMenu={editMenu}
                          deleteMenu={deleteMenu}
                          onSelect={onSelect}
                        />
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    style={{ position: "relative" }}
                  >
                    <SortableList
                      allowDrag={props.allowSort}
                      className="menu-order-list-view"
                      onSortEnd={(oldIndex, newIndex) =>
                        onSortEnd(oldIndex, newIndex, cat._id)
                      }
                      draggedItemClassName="menuListOrderDragged"
                    >
                      {cat.menu.map((item, index) => {
                        return (
                          <SortableItem key={item._id}>
                            <Box className="testing">
                              <MenuListItem
                                key={index}
                                data={item}
                                page={props.page}
                                allowSort={props.allowSort}
                                showMenuPopup={props.showMenuPopup}
                                editMenu={editMenu}
                                deleteMenu={deleteMenu}
                                onSelect={onSelect}
                              />
                            </Box>
                          </SortableItem>
                        );
                      })}
                    </SortableList>
                  </Grid>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      );
    });
  }, [
    menuCategoryList,
    listType,
    props.searchMenu,
    props?.inititalSelectedItems,
  ]);

  const TabScrollBtn = forwardRef((props, ref) => {
    const { direction, disabled, ...other } = props;

    return (
      <ButtonBase
        component="div"
        ref={ref}
        style={{
          opacity: 1,
          zIndex: 3,
          position: "absolute",
          right: direction === "right" ? 40 : 82,
        }}
        {...other}
      >
        {direction === "right" ? (
          <Grid
            className="menu-arrow-icon-grid"
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              border: "1px solid #E2E2E2",
            }}
          >
            <RightArrow />
          </Grid>
        ) : (
          <Grid
            className="menu-arrow-icon-grid"
            sx={{
              marginRight: 1,
              cursor: "pointer",
              backgroundColor: "#ffffff",
              border: "1px solid #E2E2E2",
            }}
          >
            <LeftDarkArrow />
          </Grid>
        )}
      </ButtonBase>
    );
  });

  return (
    <Grid xs={12}>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: window.innerHeight - 150 }}
        >
          <ThemeProvider theme={theme}>
            <CircularProgress color="loading_color" />
          </ThemeProvider>
        </Grid>
      ) : (
        <Grid>
          <Grid>
            {menuCategoryList.length > 0 ? (
              <Box
                sx={{
                  maxWidth: {
                    xs: window.innerWidth - 150,
                    sm: props?.tabWidth
                      ? props.tabWidth
                      : props.drawerOpen
                      ? window.innerWidth - 450
                      : window.innerWidth - 280,
                  },
                  mt: 1,
                }}
              >
                <Tabs
                  value={category}
                  variant="scrollable"
                  scrollButtons={true}
                  aria-label=""
                  orientation="horizontal"
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  allowScrollButtonsMobile
                  ScrollButtonComponent={(e) => <TabScrollBtn {...e} />}
                >
                  {menuCategoryList.map((item, index) => {
                    let filterMenu = item.menu.filter(
                      (x) =>
                        x?.name
                          ?.toLowerCase()
                          ?.match(props.searchMenu?.toLowerCase()) ||
                        x?.description
                          ?.toLowerCase()
                          ?.match(props.searchMenu?.toLowerCase())
                    );

                    if (filterMenu.length == 0) {
                      return null;
                    }
                    return (
                      <Tab
                        key={index}
                        value={item._id}
                        onClick={() => onSelectCategory(item, index)}
                        label={item.name}
                        sx={[
                          styles.categoryData,
                          category == item._id
                            ? styles.selectedCategory
                            : styles.nonSelectedCategory,
                        ]}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            ) : (
              <Box style={styles.noCatView}>
                <Typography sx={styles.noCatText}>No Items</Typography>
              </Box>
            )}
          </Grid>

          <Grid sx={{ position: "relative" }}>
            {menuOrderLoading ? (
              <Grid
                container
                style={{
                  position: "absolute",
                  zIndex: 10,
                  backgroundColor: "rgba(0,0,0,0.05)",
                  height: "100%",
                }}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <ThemeProvider theme={theme}>
                    <CircularProgress color="loading_color" />
                  </ThemeProvider>
                </Grid>
              </Grid>
            ) : null}
            {menuCategoryList.length > 0 ? (
              props.showGridIcon ? (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    top: 5,
                    right: 18,
                    zIndex: 2,
                  }}
                >
                  <Grid
                    className="menu-grid-list-view-grid"
                    sx={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #DEDEDE",
                    }}
                  >
                    {listType == "grid" ? (
                      <GridViewActiveIcon
                        height={14}
                        width={14}
                        className="menu-grid-view-icon"
                      />
                    ) : (
                      <GridViewIcon
                        height={14}
                        width={14}
                        className="menu-grid-view-icon"
                        onClick={() => selectListType("grid")}
                      />
                    )}
                    {listType == "list" ? (
                      <ListViewActiveIcon
                        height={14}
                        width={14}
                        className="cursor-pointer"
                      />
                    ) : (
                      <ListViewIcon
                        height={14}
                        width={14}
                        className="cursor-pointer"
                        onClick={() => selectListType("list")}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : null
            ) : null}
            <Grid
              id="mainViewId"
              onScroll={handleMainScroll}
              sx={{
                maxHeight: menu_list_height,
                position: "relative",
                overflow: "auto",
                mt: -0.5,
              }}
            >
              {MenuListView}
              {menuCategoryList.length > 1 ? (
                <Box id={"additionalHeight"} />
              ) : null}
            </Grid>
          </Grid>

          <ConfirmAlert
            confirmDelete={confirmDelete}
            title={"Are you sure?"}
            content={"You will not be able to recover this item"}
            firstBtn={"No"}
            secondBtn={btnLoading ? "Please Wait" : "Yes ! Delete it"}
            btnLoading={btnLoading}
            onCloseConfirmDelete={onCloseConfirmDelete}
            onConfirmDelete={confirmDeleteMenu}
          />
          <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
  };
};

export default connect(mapStateToProps, null)(CategoryMenuList);

const styles = {
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuText: {
    color: "#000",
    fontFamily: "GilroyBold",
  },
  titleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  categoryData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid #E2E2E2",
    fontFamily: "GilroyBold",
    fontSize: 13,
    textTransform: "capitalize",
    borderRadius: 4,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: 2,
    marginTop: 1,
    marginBottom: 2,
    minWidth: "10%",
  },
  noCatView: {
    display: "flex",
    height: window.innerHeight - 250,
    alignItems: "center",
    justifyContent: "center",
  },
  noCatText: {
    color: "#000",
    textAlign: "center",
    fontFamily: "GilroyMedium",
  },
  selectedCategory: {
    backgroundColor: THEME_COLOR,
    borderColor: THEME_COLOR,
    "&.Mui-selected": {
      color: "#000",
    },
  },
  nonSelectedCategory: {
    backgroundColor: "transparent",
    borderColor: "#E2E2E2",
    color: "#000000",
  },
  menuTitleText: {
    backgroundColor: "#FFF",
    color: THEME_COLOR5,
    fontFamily: "GilroyBold",
    fontSize: 18,
    zIndex: 1,
    top: -2,
    pt: 1,
    pb: 1,
  },
};
