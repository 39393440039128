const initialState = {
  userToken: '',
  refreshToken: '',
  drawerOpen: true,
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_TOKEN':
      return {
        ...state,
        userToken: action.payload,
      };

    case 'UPDATE_REFRESH_TOKEN':
      return {
        ...state,
        refreshToken: action.payload,
      };

    case 'UPDATE_DRAWER_OPEN':
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case 'LOG_OUT':
      return {
        ...state,
        userToken: '',
        refreshToken: '',
        drawerOpen: true,
      };
  }
  return state;
};

export default userDataReducer;
