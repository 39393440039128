import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, useNavigate } from "react-router-dom";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import useDebounce from "../../components/UseDebounce";
import SettingCategoryList from "../../components/restaurant/SettingCategoryList";
import SearchInput from "../../components/SearchInput";
import CategoryMenuList from "../../components/restaurant/CategoryMenuList";
import ConfirmAlert from "../../components/restaurant/ConfirmAlert";

import { connect } from "react-redux";

import * as Api from "../../api";

import { BTN_COLOR, BTN_TEXT_COLOR } from "../../constants";

import { ReactComponent as AddIcon } from "../../assets/images/add_plus_white.svg";
import { ReactComponent as BackIcon } from "../../assets/images/back.svg";

const AntTabs = styled(Tabs)({
  backgroundColor: "#FFF",
  borderRadius: 8,
  borderBottom: 0,
  height: 0,
  width: "fit-content",
  "& .MuiTabs-indicator": {
    height: 0,
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  minWidth: 150,
  height: 40,
  fontFamily: "GilroyBold",
  color: "#00000099",
  "&.Mui-selected": {
    color: "#FFF",
    backgroundColor: "#000",
    borderRadius: 8,
  },
}));

const pageSize = 20;

const SettingsMenu = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length >= 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [listLoading, setListLoading] = useState(false);
  const [tabValue, setTabValue] = useState(
    location.pathname.split("/").length >= 5
      ? location.pathname.split("/")[4]
      : "menu"
  );
  const [categoryList, setCategoryList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [searchItemName, setSearchItemName] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [categoryItemData, setCategoryItemData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const debouncedCatName = useDebounce(searchName, 500);
  const debouncedMenuName = useDebounce(searchItemName, 500);

  useEffect(() => {
    if (tabValue == "category") {
      getCategoryList(1, debouncedCatName);
    } else {
      setLoading(false);
    }
  }, [debouncedCatName, tabValue]);

  const getCategoryList = (num, search) => {
    let filter = "";
    filter =
      "pageSize=" +
      pageSize +
      "&pageNum=" +
      num +
      "&filter__idRestaurant=" +
      restaurantId;
    if (search !== "") {
      filter += "&search=" + search;
    }

    Api.getMenuCategoryList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCategoryList(data);
        setPageNum(num);
        setTotalPages(response.data.pages);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
      setListLoading(false);
    });
  };

  const onSelectPage = (value) => {
    setListLoading(true);
    if (tabValue == "category") {
      getCategoryList(value, searchName);
    }
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onTabChange = (event, newValue) => {
    let type = newValue == 0 ? "menu" : "category";
    setListLoading(true);
    setTabValue(type);
    if (type == "category") {
      getCategoryList(1, searchName);
    }
  };

  const onAddCatMenu = () => {
    if (tabValue == "category") {
      navigate("/restaurant/" + restaurantId + "/add_category");
    } else {
      navigate("/restaurant/" + restaurantId + "/add_menu");
    }
  };

  const editCategory = (data) => {
    navigate("/restaurant/" + restaurantId + "/add_category/" + data._id);
  };

  const deleteCategory = (data) => {
    let filter =
      "?pageSize=30000&pageNum=1&filter__idRestaurant=" +
      restaurantId +
      "&filter__idCategory=" +
      data._id +
      "&orderBy=order&orderByDir=asc";
    setLoading(true);
    Api.getMenuList(filter).then((response) => {
      setLoading(false);
      if (response.success) {
        let menu_list = [...response.data.rows];
        let find_menu = menu_list.filter((x) => x._idCategory == data._id);
        if (find_menu.length) {
          setCategoryItemData({ ...data, menuExist: true });
        } else {
          setCategoryItemData({ ...data, menuExist: false });
        }
        setConfirmDelete(true);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const onCloseConfirmDelete = () => {
    setConfirmDelete(false);
    setCategoryItemData(null);
  };

  const confirmDeleteMenu = () => {
    setBtnLoading(true);
    Api.deleteMenuCategory(categoryItemData._id).then(
      (response) => {
        if (response.success) {
          let catList = [...categoryList];
          setCategoryList(catList.filter((x) => x._id != categoryItemData._id));
          setConfirmDelete(false);
          setCategoryItemData(null);
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnLoading(false);
      }
    );
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {loading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid mt={-2}>
          <Grid
            item
            container
            xs={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <Box sx={styles.titleMainBox}>
                <Box sx={styles.titleViewText}>
                  <Box
                    sx={styles.backBtn}
                    onClick={() =>
                      navigate("/restaurant/" + restaurantId + "/manage_menu")
                    }
                  >
                    <BackIcon width={30} height={30} />
                  </Box>
                  <Typography mx={1.5} sx={styles.titleText}>
                    Upload Manually
                  </Typography>
                </Box>
                <Box my={1} mr={2} sx={{ minWidth: 250 }}>
                  <SearchInput
                    value={tabValue == "category" ? searchName : searchItemName}
                    setValue={(val) =>
                      tabValue == "category"
                        ? setSearchName(val)
                        : setSearchItemName(val)
                    }
                    placeholder={
                      tabValue == "category"
                        ? "Search category here"
                        : "Search menu item here"
                    }
                    inputBg={"#FFFFFF"}
                    height={48}
                  />
                </Box>
                <Box my={1}>
                  <AntTabs
                    value={tabValue == "category" ? 1 : 0}
                    onChange={onTabChange}
                  >
                    <AntTab label="Menu Settings" />
                    <AntTab label="Category Settings" />
                  </AntTabs>
                </Box>
                <Box
                  sx={styles.addCategoryBtn}
                  onClick={() => onAddCatMenu()}
                  my={1}
                >
                  <AddIcon width={18} height={18} />
                  <Typography sx={styles.addCategoryText} ml={0.5}>
                    Add {tabValue == "category" ? "Category" : "Menu"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {listLoading && tabValue == "category" ? (
            <Loader height={{ height: window.innerHeight - 150 }} />
          ) : (
            <Box mb={3} mt={1} sx={{ minHeight: window.innerHeight - 350 }}>
              <Grid item xs={12} sx={styles.mainView} py={2} px={3}>
                {tabValue == "category" ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      mb={1.5}
                      container
                      direction="row"
                      justifyContent={"flex-end"}
                    >
                      <Box
                        sx={styles.editCatOrderDiv}
                        onClick={() =>
                          navigate(
                            "/restaurant/" + restaurantId + "/category_reorder"
                          )
                        }
                      >
                        <AddIcon width={18} height={18} />
                        <Typography sx={styles.editCatOrderText} ml={0.5}>
                          Edit Category Order
                        </Typography>
                      </Box>
                    </Grid>
                    <SettingCategoryList
                      categoryList={categoryList}
                      editCategory={editCategory}
                      deleteCategory={deleteCategory}
                    />
                  </>
                ) : (
                  <CategoryMenuList
                    page={"SettingsMenu"}
                    restaurantId={restaurantId}
                    searchMenu={debouncedMenuName}
                    allowSort={true}
                    showGridIcon={true}
                    showMenuPopup={true}
                  />
                )}
              </Grid>
            </Box>
          )}

          {totalPages > 0 && tabValue == "category" ? (
            <Page
              totalPages={totalPages}
              pageNum={pageNum}
              onSelectPage={onSelectPage}
            />
          ) : null}
        </Grid>
      )}

      <ConfirmAlert
        confirmDelete={confirmDelete}
        title={
          categoryItemData?.menuExist === true
            ? "Menu items exists for this category"
            : "Are you sure?"
        }
        content={
          categoryItemData?.menuExist === true
            ? "Please delete the menu items before deleting the category"
            : "You will not be able to recover this category"
        }
        name={categoryItemData?.name ? categoryItemData?.name : null}
        firstBtn={categoryItemData?.menuExist === true ? "Cancel" : "No"}
        secondBtn={
          btnLoading
            ? "Please Wait"
            : categoryItemData?.menuExist === true
            ? "Ok"
            : "Yes ! Delete it"
        }
        btnLoading={btnLoading}
        onCloseConfirmDelete={onCloseConfirmDelete}
        onConfirmDelete={
          categoryItemData?.menuExist === true
            ? onCloseConfirmDelete
            : confirmDeleteMenu
        }
        themeMode={props.themeMode}
      />
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
  };
};

export default connect(mapStateToProps, null)(SettingsMenu);

const styles = {
  settingsText: {
    fontFamily: "GilroyBold",
  },
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  titleViewText: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  titleText: {
    color: "#000000",
    fontSize: 18,
    fontFamily: "GilroySemiBold",
  },
  mainView: {
    backgroundColor: "#FFF",
    borderRadius: 8,
  },
  editCatOrderDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: BTN_COLOR,
    borderRadius: "8px",
    cursor: "pointer",
    height: 45,
    width: "fit-content",
    px: 1.5,
    py: 0.8,
  },
  editCatOrderText: {
    color: BTN_TEXT_COLOR,
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    marginLeft: 1,
  },
  addCategoryBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: BTN_COLOR,
    borderRadius: "8px",
    cursor: "pointer",
    height: 45,
    width: "fit-content",
    minWidth: 150,
    ml: 1,
    px: 1.5,
    py: 0.8,
  },
  addCategoryText: {
    color: BTN_TEXT_COLOR,
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    marginLeft: 1,
  },
};
