import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputAdornment from "@mui/material/InputAdornment";

import { ReactComponent as CalenderIcon } from "../assets/images/calender_gray.svg";

const SettingDatePicker = (props) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  let bgColor = props.backgroundColor;

  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[
              { fontSize: 12, fontFamily: "GilroySemiBold" },
              props?.labelStyle ? props.labelStyle : null,
            ]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={props.value}
          inputFormat={props.inputFormat}
          onChange={props.onChange}
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          inputProps={{
            readOnly: true,
          }}
          disabled={props.disabled}
          disableMaskedInput
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              onClick={() => (props.disabled ? null : setDatePickerOpen(true))}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  borderRadius: "10px",
                  height: props?.height ? props.height : 46,
                  fontFamily: "GilroySemiBold",
                  fontSize: 12,
                  py: 1,
                  px:
                    typeof props.hideInput != "undefined"
                      ? `${props.hideInput == true ? 0 : 1} !important`
                      : 1,
                  backgroundColor: `${bgColor} !important`,
                  "&:before, :after, :hover:not(.Mui-disabled):before": {
                    borderBottom: 0,
                  },
                },
                "& .MuiFilledInput-input": {
                  color: props?.color ? `${props.color} !important` : "#000",
                  p: "0 !important",
                  cursor: props?.cursor ? props.cursor : "default",
                  textAlign: props?.inputTextAlign
                    ? props.inputTextAlign
                    : "left",
                },
                "& .MuiFormLabel-root": {
                  display: "none !important",
                },
                "& .MuiInputAdornment-root": {
                  marginLeft:
                    typeof props.rightView != "undefined" &&
                    props?.rightView == null
                      ? `0 !important`
                      : "8px",
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  mt: 0.8,
                  "& .MuiFormLabel-asterisk": {
                    display: "none",
                  },
                },
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: props?.placeholder ? props.placeholder : "",
              }}
              InputProps={{
                startAdornment:
                  typeof props.leftView != "undefined" ? (
                    <InputAdornment position="start">
                      {props.leftView}
                    </InputAdornment>
                  ) : (
                    <InputAdornment>
                      <CalenderIcon width={20} height={20} />
                    </InputAdornment>
                  ),
                endAdornment:
                  typeof props.rightView != "undefined" ? (
                    <InputAdornment position="end">
                      {props.rightView}
                    </InputAdornment>
                  ) : (
                    <InputAdornment>
                      <CalenderIcon width={20} height={20} />
                    </InputAdornment>
                  ),
              }}
            />
          )}
        />
      </LocalizationProvider>
      <Box>
        {props?.error ? (
          <Typography
            sx={{
              color: '#CD0000',
              fontSize: 10,
              fontFamily: "GilroyMedium",
            }}
          >
            {props.error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default SettingDatePicker;
