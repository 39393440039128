import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import EventItem from "../../components/restaurant/EventItem";
import Page from "../../components/Page";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import SearchItem from "../../components/restaurant/SearchItem";
import useDebounce from "../../components/UseDebounce";

import { ReactComponent as SearchIcon } from "../../assets/images/search1.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { ReactComponent as BackIcon } from "../../assets/images/back.svg";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #AAA",
  "& .MuiTabs-indicator": {
    backgroundColor: "#000",
    borderBottom: "3px solid #000",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  minWidth: 150,
  fontFamily: "GilroyBold",
  "&.Mui-selected": {
    color: "#000",
  },
}));

const PAGE_SIZE = 16;

const Events = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [cardLoading, setCardLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchEvent, setSearchEvent] = useState("");
  const [searchEventList, setSearchEventList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const debouncedEventName = useDebounce(searchEvent, 500);

  useEffect(() => {
    let start_date = "";
    let end_date = "";
    if (tabValue == 0) {
      let date1 = moment().valueOf();
      start_date = "lte-" + date1;
      end_date = "gte-" + date1;
      getEventList(1, start_date, end_date);
    } else if (tabValue == 1) {
      let date1 = moment().valueOf();
      start_date = "gt-" + date1;
      getEventList(1, start_date, "");
    } else if (tabValue == 2) {
      let date1 = moment().valueOf();
      end_date = "lt-" + date1;
      getEventList(1, "", end_date);
    }
  }, [tabValue]);

  useEffect(() => {
    if (debouncedEventName) {
      getEventList("all", startDate, endDate);
    } else {
      setSearchEventList([]);
    }
  }, [debouncedEventName]);

  const getEventList = (pageNo, start_date, end_date) => {
    let filter = `?pageSize=${PAGE_SIZE}&filter__idRestaurant=${restaurantId}`;
    if (pageNo != "all") {
      filter = `?pageSize=${PAGE_SIZE}&pageNum=${pageNo}&filter__idRestaurant=${restaurantId}`;
      setCardLoading(true);
    } else {
      filter += `&search=${searchEvent}`;
    }

    if (start_date !== "") {
      filter += `&filter_eventStartDate=${start_date}`;
    }
    if (end_date !== "") {
      filter += `&filter_eventEndDate=${end_date}`;
    }

    Api.getMyEventList(filter).then((response) => {
      if (pageNo != "all") {
        if (response.success) {
          let data = response.data.rows;
          setEventList(data);
          setPageNum(pageNo);
          setStartDate(start_date);
          setEndDate(end_date);
          setTotalPages(response.data.pages);
        } else {
          setCardLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setCardLoading(false);
      } else {
        if (response.success) {
          let data = response.data.rows;
          setSearchEventList(data);
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    });
  };

  const onTabChange = (event, newValue) => {
    setSearchEvent("");
    setTabValue(newValue);
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      <Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <Box sx={styles.titleViewText}>
              <Box
                sx={styles.backBtn}
                onClick={() => navigate("/restaurant/" + restaurantId)}
              >
                <BackIcon width={30} height={30} />
              </Box>
              <Typography mx={1.5} sx={styles.titleText}>
                Events
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} className="search-event-input">
            <TextField
              fullWidth
              focused
              value={searchEvent}
              color="#fff"
              variant="filled"
              autoFocus={searchEvent != ""}
              placeholder={"Search events here"}
              hiddenLabel={true}
              onChange={(e) => setSearchEvent(e.target.value)}
              InputProps={{
                disableUnderline: true,
                autoComplete: "none",
                sx: {
                  fontSize: 12,
                  fontFamily: "GilroySemiBold",
                  height: 40,
                  borderRadius: 2,
                  width: "100%",
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ marginLeft: 1, cursor: "pointer" }}
                  >
                    <SearchIcon height={15} width={15} />
                  </InputAdornment>
                ),
                endAdornment:
                  searchEvent != "" ? (
                    <InputAdornment
                      onClick={() => setSearchEvent("")}
                      position="end"
                      sx={{ marginLeft: 1, cursor: "pointer" }}
                    >
                      <CloseIcon height={15} width={15} />
                    </InputAdornment>
                  ) : null,
              }}
            />
            {searchEventList.length ? (
              <Grid item xs={12} sx={styles.searchListView}>
                {searchEventList.map((x, i) => {
                  return (
                    <SearchItem
                      type="event"
                      key={i}
                      data={x}
                      lastIndex={searchEventList.length - 1 == i ? 1 : 0}
                    />
                  );
                })}
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Box sx={{ width: "100%" }} mt={1}>
          <Box>
            <AntTabs value={tabValue} onChange={onTabChange}>
              <AntTab label="Current Events" />
              <AntTab label="Upcoming" />
              <AntTab label="Completed" />
            </AntTabs>
            <Box sx={{ p: 3 }} />
          </Box>
        </Box>

        {cardLoading ? (
          <Loader height={{ height: window.innerHeight - 350 }} />
        ) : (
          <Grid style={{ minHeight: window.innerHeight - 350 }}>
            <Grid container direction="row">
              {eventList.map((item, index) => {
                return (
                  <EventItem
                    key={index}
                    firstItem={index % 4 === 0}
                    lastItem={(index + 1) % 4 === 0}
                    data={item}
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
        {eventList.length ? (
          <Page
            totalPages={totalPages}
            pageNum={pageNum}
            onSelectPage={(value) => getEventList(value, startDate, endDate)}
          />
        ) : null}
      </Grid>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default connect(null, null)(Events);

const styles = {
  titleViewText: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  titleText: {
    color: "#000000",
    fontSize: 18,
    fontFamily: "GilroySemiBold",
  },
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuText: {
    fontFamily: "GilroyBold",
  },
  searchListView: {
    position: "absolute",
    backgroundColor: "#FFF",
    width: "100%",
    maxHeight: 200,
    borderRadius: 2,
    overflow: "auto",
    my: 0.5,
  },
};
