import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./ManageMenu.module.css";

import Container from "../../components/Container";

import { ReactComponent as BackIcon } from "../../assets/images/back.svg";
import { ReactComponent as ManageMenuIcon } from "../../assets/images/manage_menu.svg";

let ICON_SIZE = 25;

const ManageMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );

  let MANAGE_MENU_LIST = [
    {
      page: "upload_manually ",
      label: "Upload Manually ",
      href: "/restaurant/" + restaurantId + "/settings",
      icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "uber_eats",
      label: "Uber Eats",
      href: "/restaurant/" + restaurantId + "/uber_eats",
      icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "swiggy",
      label: "Swiggy",
      href: "/restaurant/" + restaurantId + "/swiggy",
      icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "talabath",
      label: "Talabath",
      href: "/restaurant/" + restaurantId,
      icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
  ];

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Box className={styles.titleViewText} mb={2}>
            <Box
              className={styles.backBtn}
              onClick={() => navigate("/restaurant/" + restaurantId)}
            >
              <BackIcon width={30} height={30} />
            </Box>
            <Typography ml={1.5} className={styles.titleText}>
              Manage Menu
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
          <Grid container item xs={12} md={8} className={styles.listBtnMainDiv}>
            {MANAGE_MENU_LIST.map((x, i) => {
              return (
                <Grid key={i} item xs={6} md={4}>
                  <Box
                    className={styles.listBtnDiv}
                    onClick={() => navigate(x.href)}
                    sx={{ width: { xs: "98%", md: "90%" } }}
                    px={1.5}
                    py={1.3}
                    mb={3}
                  >
                    <Box>{x.icon}</Box>
                    <Typography className={styles.listBtnDivText}>
                      {x.label}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManageMenu;
