import axios from "axios";
import { store } from "../redux/store";
import { logOut, updateUserToken } from "../redux/actions/userDataActions";

let apiUrl = process.env.REACT_APP_ADMIN_API_URL;
let apiSdkUrl = process.env.REACT_APP_SDK_API_URL;
let sdkModelApiKey = process.env.REACT_APP_SDK_MODEL_API_KEY;

function userToken() {
  const state = store.getState();
  let token = state.userData.userToken;
  return token;
}

export async function login(data) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("auth/login", config);
}

export async function logout() {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("auth/logout", config);
}

export async function restaurantList(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant" + filter, config);
}

export async function getRestaurantCategoryList(type) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  if (type === "all") {
    return callApi("restaurant/category/all", config);
  }
}

export async function uploadImageFile(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("files/upload-image", config);
}

export async function addRestaurant(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant", config);
}

export async function addRestaurantMenuCategory(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/category", config);
}

export async function addRestaurantMenuItem(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu", config);
}

export async function eventCategoryList(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("event/category" + filter, config);
}

export async function createEvent(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("event", config);
}

export async function getEventList(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("event" + filter, config);
}

export async function getRestaurantData(restaurantId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return callApi("restaurant/" + restaurantId, config);
}

export async function updateRestaurant(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant/" + restaurantId, config);
}

export async function getMenuCategoryList(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/category?" + filter, config);
}

export async function getMenuList(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu" + filter, config);
}

export async function addMenuCategory(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/category", config);
}

export async function updateMenuCategory(data, catId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/category/" + catId, config);
}

export async function addMenu(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu", config);
}

export async function updateMenu(data, itemId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/" + itemId, config);
}

export async function deleteMenu(itemId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/" + itemId, config);
}

export async function menuRearrange(data) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/rearrange", config);
}

export async function getMyEventList(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("event" + filter, config);
}

export async function getCategoryData(catId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/category/" + catId, config);
}

export async function getMenuData(itemId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/" + itemId, config);
}

export async function getArModelData(modelId) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sdkModelApiKey,
    },
  };

  return callSdkApi("model/" + modelId, config);
}

export async function createArModel(data) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sdkModelApiKey,
    },
    data: data,
  };

  return callSdkApi("model", config);
}

export async function uploadArModel(data, id) {
  let config = {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Api-Key": sdkModelApiKey,
    },
    data: data,
  };

  return callSdkApi("model/" + id + "/update/glb", config);
}

export async function updateArModelAttribute(data, id) {
  let config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sdkModelApiKey,
    },
    data: data,
  };

  return callSdkApi("model/" + id + "/update_attributes", config);
}

export async function deleteArModel(modelId) {
  let config = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sdkModelApiKey,
    },
  };

  return callSdkApi("model/" + modelId, config);
}

export async function getOrders(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("orders?" + filter, config);
}

export async function getGiftCardTemplate(filter, restaurantId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("gift-card-template" + filter, config);
}

export async function addUpdateGiftCardTemplate(
  data,
  restaurantId,
  templateId
) {
  let token = userToken();
  let config = {
    method: templateId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "Restaurant-ID": restaurantId,
    },
    data: data,
  };

  if (templateId) {
    return callApi("gift-card-template/" + templateId, config);
  } else {
    return callApi("gift-card-template", config);
  }
}

export async function deleteGiftCardTemplate(restaurantId, templateId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("gift-card-template/" + templateId, config);
}

export async function getUserList(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("user" + filter, config);
}

export async function importFromUberEatsSwiggy(
  data,
  restaurantId,
  type,
  currency
) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi(
    "menu/" + restaurantId + "/import/" + type + "?currency=" + currency,
    config
  );
}

export async function deleteRestaurant(resId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant/" + resId, config);
}

export async function deleteMenuCategory(catId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/category/" + catId, config);
}

export async function menuModelRearrange(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "Restaurant-ID": restaurantId,
    },
    data: data,
  };

  return callApi("menu/rearrange_model", config);
}

/**
 * Private functions
 */
async function callApi(endpoint, config) {
  const url = `${apiUrl}${endpoint}`;

  console.log("Url: ", url);
  console.log("Config: ", config);

  return axios({
    ...config,
    url: url,
  })
    .then((response) => {
      console.log("-----API RESPONSE-----", response.data);
      return response.data;
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );

      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          if (error.response.data.data === "TokenExpiredError") {
            //refresh token
            return await refreshToken(endpoint, config);
          } else if (error.response.data.data === "JsonWebTokenError") {
            console.log("logout");
            await store.dispatch(logOut(""));
          } else if (
            error.response.data.code == 500 &&
            error.response.data.msg === "jwt expired"
          ) {
            console.log("logout");
            await store.dispatch(logOut(""));
          } else {
            return {
              success: false,
              msg: error.response.data.msg,
            };
          }
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}

async function callSdkApi(endpoint, config) {
  const url = `${apiSdkUrl}${endpoint}`;

  console.log("Url: ", url);
  console.log("Config: ", config);

  return axios({
    ...config,
    url: url,
  })
    .then((response) => {
      console.log("-----API RESPONSE-----", response.data);
      return response.data;
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );
      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          if (error.response.data.data === "TokenExpiredError") {
            //refresh token
            return await refreshToken(endpoint, config);
          } else if (error.response.data.data === "JsonWebTokenError") {
            console.log("logout");
            await store.dispatch(logOut(""));
          } else {
            return {
              success: false,
              msg: error.response.data.msg,
            };
          }
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}

async function refreshToken(endpoint1, config1) {
  const state = store.getState();
  let token = state.userData.refreshToken;
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const url = `${apiUrl}auth/refresh`;
  return await axios({
    ...config,
    url: url,
  })
    .then(async (response) => {
      console.log("-----API RESPONSE-----", response.data);
      await store.dispatch(updateUserToken(response.data.data.token));
      config1["headers"] = {
        ...config1["headers"],
        Authorization: "Bearer " + response.data.data.token,
      };
      return await callApi(endpoint1, config1);
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );
      console.log("logout");
      await store.dispatch(logOut(""));
      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          return {
            success: false,
            msg: error.response.data.msg,
          };
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}
