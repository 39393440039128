import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./ArModelSelectMenu.module.css";

import Container from "../../components/Container";
import CategoryMenuList from "../../components/restaurant/CategoryMenuList";

import { ReactComponent as BackIcon } from "../../assets/images/back.svg";

const ArModelSelectMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );

  const onSelectMenu = (item) => {
    navigate("/restaurant/" + restaurantId + "/model/" + item._id);
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      <Grid container item xs={12}>
        <Box className={styles.titleViewText} mb={2}>
          <Box
            className={styles.backBtn}
            onClick={() =>
              navigate("/restaurant/" + restaurantId + "/ar_models")
            }
          >
            <BackIcon width={30} height={30} />
          </Box>
          <Typography ml={1.5} className={styles.titleText}>
            Select the menu
          </Typography>
        </Box>
        <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
          <CategoryMenuList
            page={"ArModelSelectMenu"}
            restaurantId={restaurantId}
            searchMenu={""}
            onSelectMenu={onSelectMenu}
            allowSort={false}
            showGridIcon={true}
            showMenuPopup={false}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ArModelSelectMenu;
