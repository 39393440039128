export const API_KEY = process.env.REACT_APP_MAP_API_KEY;

export const THEME_COLOR = '#ffe342';
export const THEME_COLOR2 = '#f14136';
export const THEME_COLOR3 = '#AAA';
export const THEME_COLOR4 = '#C4C4C4';
export const THEME_COLOR5 = '#272D2F';
export const LOADING_COLOR = '#000';
export const BTN_COLOR = '#000';
export const BTN_TEXT_COLOR = '#FFF';

export const RESTAURANT_APP_URL = process.env.REACT_APP_RESTAURANT_APP_URL;

export const CURRENCY_LIST = [
  {label: 'Euro (£)', value: 'euro', currency: 'GBP', symbol: '£'},
  {label: 'Dollar ($)', value: 'dollar', currency: 'USD', symbol: '$'},
  {label: 'Dirham (AED)', value: 'dirham', currency: 'AED', symbol: 'د.إ'},
  {label: 'Rupee (INR)', value: 'rupee', currency: 'INR', symbol: '₹'},
  {label: 'Bahraini dinar (BD)', value: 'bahraini dinar', currency: 'BD', symbol: 'BD'},
  {label: 'Saudi riyal (SR)', value: 'saudi riyal', currency: 'SR', symbol: 'SR'},
];

export const CURRENCY_SYMBOL = CURRENCY_LIST.reduce((o, key) => ({ ...o, [key.currency]: key.symbol}), {});

export const MENU_TYPE = ['Non vegetarian', 'Vegetarian', 'Plant based', 'Seafood'];

export const DINEIN = 1;
export const DELIVERY = 2;
export const PICKUP = 3;
export const TABLE = 4;

export const APP_TYPE_FOODO = "FOODO";
export const APP_TYPE_GOPOSH = "GOPOSH";

