import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";

import AddRestaurantImagesUpload from "./../components/AddRestaurantImagesUpload";
import RestaurantBtn from "./RestaurantBtn";
import AlertMsg from "./AlertMsg";
import SelectizeTextField from "./SelectizeTextField";

import {
  THEME_COLOR,
  THEME_COLOR2,
  THEME_COLOR3,
  THEME_COLOR4,
} from "../constants";

import "./../assets/css/custom.css";

const textInputCss = {
  "& .MuiInput-underline:before": {
    borderBottom: `1.5px solid ${THEME_COLOR2}`,
  },
  "& .MuiInput-underline:after": {
    borderBottom: `1.5px solid ${THEME_COLOR2}`,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottom: 0,
  },
};

const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR4,
    },
    yellow: {
      main: THEME_COLOR,
    },
  },
});

const AddRestaurantInfo = (props) => {
  const [category, setCategory] = useState("");
  const [option, setOption] = useState("dine_in");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [mobile, setMobile] = useState(null);
  const [desc, setDesc] = useState("");
  const [slug, setSlug] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [logoImage, setLogoImage] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let list = [];
    getCountries().map((x) => {
      let code = getCountryCallingCode(x);
      list.push({
        country: x,
        code: `+${code}`,
        name: `${x} (+${code})`,
      });
    });

    let filter = list.filter((x) => x.country == props.countryCode);
    if (filter.length) {
      let data = filter[0];
      setMobileCode(data);
      let code1 = data.code;
      if (props.mobile.includes(code1, 0)) {
        setMobile(props.mobile.replace(code1, ""));
      }
    } else {
      let filter1 = list.filter((x) => x.code == "+1");
      let filter2 = list.filter((x) => x.code == "+44");
      if (filter1.length && props.mobile.includes("+1", 0)) {
        setMobileCode(filter1[0]);
        setMobile(props.mobile.replace("+1", ""));
      } else if (filter2.length && props.mobile.includes("+44", 0)) {
        setMobileCode(filter2[0]);
        setMobile(props.mobile.replace("+44", ""));
      } else {
        setMobile(props.mobile.replace("+", ""));
      }
    }
    setCountryList(list);
  }, [props.countryCode, props.mobile]);

  useEffect(() => {
    setName(props.name);
    setEmail(props.email);
    setDesc(props.desc);
    setSlug(props?.slug);
    setCategory(props.category);
    setOption(props.option);
    // if (props.type == 'add') {
    //   setLogoImage(props.logo);
    //   setImageList(props.imageList);
    // }
  }, [props]);

  useEffect(() => {
    // if (props.type == 'add') {
    //   let upload_list = imageList.filter(x => x.upload == false);
    //   if (upload_list.length > 0) {
    //     setImageLoading(true);
    //   } else {
    //     setImageLoading(false);
    //   }
    // }
  }, [imageList]);

  const onCheckboxClick = (val) => {
    setOption(val);
  };

  const onNextBtnClick = (e) => {
    e.preventDefault();
    // if(props.type == 'add') {
    //   if (data.logo == '') {
    //     setMsgAlert({open: true, message: 'Logo is required', msgType: 'error'});
    //     return;
    //   }
    //
    //   if (data.imageList.length <= 0) {
    //     setMsgAlert({open: true, message: 'Image is required', msgType: 'error'});
    //     return;
    //   }
    // }

    if (props.type == "add") {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) == false) {
        setMsgAlert({ open: true, message: "Invalid Email", msgType: "error" });
        return;
      }
    }

    if (
      mobileCode == null ||
      typeof mobileCode?.code == "undefined" ||
      typeof mobileCode?.country == "undefined"
    ) {
      setMsgAlert({
        open: true,
        message: "Mobile Code is required",
        msgType: "error",
      });
      return;
    }

    if (/[^0-9]/g.test(mobile)) {
      setMsgAlert({
        open: true,
        message: "Invalid Contact Number",
        msgType: "error",
      });
      return;
    }

    if (mobile.length < 5) {
      setMsgAlert({
        open: true,
        message: "Minimum length 5 is required for Contact Number",
        msgType: "error",
      });
      return;
    }

    if (slug) {
      if (/[^A-Za-z]/g.test(slug)) {
        setMsgAlert({
          open: true,
          message: "Only characters are allowed",
          msgType: "error",
        });
        return;
      }
    }

    let data = {
      name: name,
      email: email,
      countryCode: mobileCode?.country,
      mobile: mobileCode?.code + "" + mobile,
      desc: desc,
      slug: slug,
      option: option,
      category: category,
      // imageList: imageList,
      // logo: logoImage,
    };

    props.resInfo(data);
  };

  // const uploadFile = (list, loading, type) => {
  //   if (type == 'loading') {
  //     setImageList(prev => [...prev, list]);
  //   } else if (type == 'success') {
  //     setImageList(prev => prev.map((el) => el.id == list.id ? {...el, ...list} : el));
  //   } else if (type == 'error') {
  //     setImageList(prev => prev.filter(x => x.id !== list.id))
  //   }
  // }
  //
  // const uploadLogoFile = (data) => {
  //   setLogoImage(data);
  //   setImageLoading(false);
  // }

  return (
    <Grid component="form" autoComplete="off" onSubmit={onNextBtnClick}>
      {/*{props.type == 'add' ?*/}
      {/*  <Grid mb={5}>*/}
      {/*    <AddRestaurantImagesUpload*/}
      {/*      logoImage={logoImage}*/}
      {/*      imageList={imageList}*/}
      {/*      imageLoading={imageLoading}*/}
      {/*      setImageLoading={setImageLoading}*/}
      {/*      onSetAlertMsg={setMsgAlert}*/}
      {/*      uploadFile={uploadFile}*/}
      {/*      uploadLogoFile={uploadLogoFile}*/}
      {/*    />*/}
      {/*  </Grid> : null}*/}

      <Grid
        container
        direction="row"
        item
        xs={12}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={5.3} className="restaurant-first-data-grid">
          <Grid>
            <ThemeProvider theme={theme}>
              <TextField
                focused
                fullWidth
                required
                id="name"
                label="Name"
                color="gray"
                variant="standard"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  style: { color: THEME_COLOR3 },
                  sx: { ...styles.inputLabelText },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: { ...styles.textInputValue },
                }}
              />
            </ThemeProvider>
          </Grid>
          {props.type == "add" ? (
            <Grid className="restaurant-data-grid">
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  fullWidth
                  id="email"
                  label="Email"
                  color="gray"
                  type="email"
                  variant="standard"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3 },
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.textInputValue },
                  }}
                />
              </ThemeProvider>
            </Grid>
          ) : null}
          <Grid mt={"24px"}>
            <label className="restaurant-label" style={styles.labelText}>
              Contact Number *
            </label>
            <ThemeProvider theme={theme}>
              <Grid
                container
                direction="row"
                style={{ alignItems: "flex-end" }}
              >
                <Grid item xs={4}>
                  <SelectizeTextField
                    page={"AddRestaurantInfo"}
                    multiple={false}
                    value={mobileCode}
                    onChange={(val) => {
                      setMobileCode(val);
                    }}
                    backgroundColor={"transparent"}
                    options={countryList}
                    variant={"filled"}
                    label={""}
                    placeholder={""}
                    borderBottom={"2px solid " + THEME_COLOR4}
                    borderRadius={"0px"}
                    disableClearable={true}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Input
                    required
                    disableUnderline
                    id="contactNumber"
                    color="gray"
                    sx={{ ...styles.selectBox, width: "100%" }}
                    value={mobile}
                    onChange={(e) =>
                      setMobile(e.target.value.replace(/[^0-9]/g, ""))
                    }
                    inputProps={{
                      autoComplete: "none",
                      pl: 1,
                      sx: {
                        "&::placeholder": {
                          fontSize: 13,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </Grid>
          <Grid className="restaurant-data-grid">
            <label className="restaurant-label" style={styles.labelText}>
              Options
            </label>
            <ThemeProvider theme={theme}>
              <FormGroup className="restaurant-main-option">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="yellow"
                      checked={option === "dine_in"}
                      onChange={() => onCheckboxClick("dine_in")}
                    />
                  }
                  label={
                    <Typography sx={styles.optionLabel}>Dine in</Typography>
                  }
                  className="restaurant-option"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="yellow"
                      checked={option === "take_away"}
                      onChange={() => onCheckboxClick("take_away")}
                    />
                  }
                  label={
                    <Typography sx={styles.optionLabel}>Take Away</Typography>
                  }
                  className="restaurant-option"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="yellow"
                      checked={option === "both"}
                      onChange={() => onCheckboxClick("both")}
                    />
                  }
                  label={<Typography sx={styles.optionLabel}>Both</Typography>}
                />
              </FormGroup>
            </ThemeProvider>
          </Grid>
        </Grid>

        <Grid item xs={12} md={5.3} className="restaurant-second-data-grid">
          <Grid>
            <label className="restaurant-label" style={styles.labelText}>
              Category
            </label>
            <ThemeProvider theme={theme}>
              <Select
                fullWidth
                required
                disableUnderline
                sx={styles.selectBox}
                color="gray"
                variant="standard"
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                {props.categoryList.map((item, index) => (
                  <MenuItem
                    sx={styles.menuItemText}
                    key={index}
                    value={item._id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </Grid>
          <Grid className="restaurant-data-grid">
            <ThemeProvider theme={theme}>
              <TextField
                multiline
                fullWidth
                focused
                required
                id="description"
                label="Description"
                color="gray"
                rows={5}
                variant="standard"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                InputLabelProps={{
                  style: { color: THEME_COLOR3 },
                  sx: { ...styles.inputLabelText },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: { ...styles.textInputValue },
                }}
              />
            </ThemeProvider>
          </Grid>
          <Grid className="restaurant-data-grid">
            <ThemeProvider theme={theme}>
              <TextField
                focused
                fullWidth
                required={false}
                id="slug"
                label="Slug"
                color="gray"
                variant="standard"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                InputLabelProps={{
                  style: { color: THEME_COLOR3 },
                  sx: { ...styles.inputLabelText },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: { ...styles.textInputValue },
                }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>
      <RestaurantBtn activeTab={0} loading={imageLoading} />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Grid>
  );
};

export default AddRestaurantInfo;

const styles = {
  labelText: {
    color: THEME_COLOR3,
    fontFamily: "GilroyRegular",
  },
  inputLabelText: {
    fontFamily: "GilroyRegular",
  },
  textInputValue: {
    fontSize: 14,
    fontFamily: "GilroySemiBold",
  },
  callCodeText: {
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    color: "#000",
  },
  selectBox: {
    borderBottom: "2px solid " + THEME_COLOR4,
    fontSize: 14,
    fontFamily: "GilroySemiBold",
  },
  menuItemText: {
    fontSize: 13,
    fontFamily: "GilroySemiBold",
  },
  optionLabel: {
    fontSize: 13,
    fontFamily: "GilroySemiBold",
  },
};
