import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./CategoryReorder.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import EditCategoryOrder from "../../components/restaurant/EditCategoryOrder";

import { ReactComponent as BackIcon } from "../../assets/images/back.svg";

const CategoryReorder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [catList, setCatList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let params = location.pathname.split("/");
    if (params.length >= 4) {
      getCategoryList();
    } else {
      setIsLoading(false);
    }
  }, []);

  const getCategoryList = () => {
    setIsLoading(true);
    let filter =
      "pageSize=100000&pageNum=1&filter__idRestaurant=" + restaurantId;
    Api.getMenuCategoryList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCatList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 200 }} />
      ) : (
        <Grid container item xs={12}>
          <Box className={styles.titleViewText} mb={2}>
            <Box
              className={styles.backBtn}
              onClick={() =>
                navigate("/restaurant/" + restaurantId + "/settings/category")
              }
            >
              <BackIcon width={30} height={30} />
            </Box>
            <Typography ml={1.5} className={styles.titleText}>
              Re-Order Category
            </Typography>
          </Box>
          <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
            <Grid item xs={7}>
              <EditCategoryOrder
                categoryList={catList}
                onCancel={() =>
                  navigate("/restaurant/" + restaurantId + "/settings/category")
                }
                onSubmit={() =>
                  navigate("/restaurant/" + restaurantId + "/settings/category")
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default CategoryReorder;
