import * as actionTypes from './types';

export const updateUserToken = data => ({
  type: actionTypes.UPDATE_USER_TOKEN,
  payload: data,
});

export const updateRefreshToken = data => ({
  type: actionTypes.UPDATE_REFRESH_TOKEN,
  payload: data,
});

export const updateDrawerOpen = data => ({
  type: actionTypes.UPDATE_DRAWER_OPEN,
  payload: data,
});

export const logOut = () => ({
  type: actionTypes.LOG_OUT,
});
