import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { ReactComponent as Bell } from "./../assets/images/bell.svg";
import { ReactComponent as Bar } from "./../assets/images/3bar.svg";
import { ReactComponent as LogoutIcon } from "./../assets/images/logout.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search.svg";
import { ReactComponent as ProfilePic } from "../assets/images/profile.svg";

const drawerWidth = 240;
const drawerWindowWidth = 75;

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(props.searchVal);
  }, [props.searchVal]);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const menu_id = open ? "menu-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: !props.open ? drawerWindowWidth : drawerWidth,
      width: `calc(100% - ${!props.open ? drawerWindowWidth : drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar
      position="fixed"
      sx={{
        width: {
          sm: `calc(100% - ${!props.open ? drawerWindowWidth : drawerWidth}px)`,
        },
        ml: { sm: `${!props.open ? drawerWindowWidth : drawerWidth}px` },
        boxShadow: "none",
        zIndex: 10,
      }}
    >
      <Toolbar sx={styles.mainNav}>
        <Box onClick={props.handleDrawer} sx={{ cursor: "pointer" }}>
          <Bar />
        </Box>

        {props.searchLabel ? (
          <TextField
            fullWidth
            focused
            value={search}
            color="gray"
            variant="filled"
            autoFocus={search != ""}
            placeholder={"Search your " + props.searchLabel}
            hiddenLabel={true}
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value == "") {
                props.onSearch("");
              }
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                props.onSearch(search);
              }
            }}
            InputProps={{
              disableUnderline: true,
              sx: {
                borderRadius: 10,
                fontSize: 15,
                fontFamily: "GilroySemiBold",
                height: 40,
                ml: 3,
                width: "80%",
              },
              startAdornment: (
                <InputAdornment
                  onClick={() => props.onSearch(search)}
                  position="start"
                  sx={{ marginLeft: 1.5, cursor: "pointer" }}
                >
                  <SearchIcon height={15} width={15} />
                </InputAdornment>
              ),
            }}
          />
        ) : null}

        <Box sx={styles.navBox}>
          <Grid style={{ position: "relative" }}>
            <Bell style={{ marginRight: 20 }} />
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={styles.notifGrid}
            >
              <Typography style={{ fontSize: 8 }}>10</Typography>
            </Grid>
          </Grid>

          <IconButton
            aria-describedby={menu_id}
            variant="contained"
            onClick={handleClick}
          >
            <ProfilePic width="35" height="35" />
          </IconButton>
          <Popover
            id={menu_id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{
              top: 50,
              left: window.innerWidth - 180,
            }}
          >
            <List sx={{ width: 150 }}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("/logout")}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <LogoutIcon width={20} height={20} />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

const styles = {
  mainNav: {
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  notifGrid: {
    backgroundColor: "red",
    position: "absolute",
    borderRadius: 10,
    width: 15,
    height: 15,
    top: -4,
    right: 18,
  },
  navBox: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
};
