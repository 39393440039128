import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Switch from '@mui/material/Switch';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';

import RestaurantBtn from "./RestaurantBtn";

import {THEME_COLOR3} from "../constants";

import './../assets/css/custom.css';

const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR3,
    },
    red: {
      main: '#FF0000',
    },
  },
});

const AddRestaurantInfo = (props) => {
  const [openingHourList, setOpeningHourList] = useState(props.openingHourList);

  const onActiveSwitch = (data) => {
    let openingHour = [...openingHourList]
    let openingHourIndex = openingHour.findIndex((item) => item.id === data.id);
    if (openingHourIndex >= 0) {
      openingHour[openingHourIndex]['active'] = !openingHour[openingHourIndex]['active'];
      setOpeningHourList(openingHour);
    }
  };

  const openCloseTime = (val, data, type) => {
    let openingHour = [...openingHourList];
    let openingHourIndex = openingHour.findIndex((item) => item.id === data.id);
    if (openingHourIndex >= 0) {
      if (type === 'open') {
        openingHour[openingHourIndex]['open'] = val;
      } else {
        openingHour[openingHourIndex]['close'] = val;
      }
      setOpeningHourList(openingHour);
    }
  };

  const onSaveBtnClick = () => {
    props.resOpeningHours(openingHourList);
  };

  return (
    <Grid>
      <Grid
        container
        item
        component="form"
        noValidate
        autoComplete="off"
        justifyContent="center">
        {openingHourList.map((item, index) => {
          return (
            <Grid container item xs={9} key={index} sx={{alignItems: 'center', minHeight: 60}} mt={2}>
              <Grid container direction="row" item xs={12} md={4} mt={2}>
                <Grid item xs={12} md={6}>
                  <label className="day-label" style={{fontFamily: 'GilroySemiBold'}}>{item.day}</label>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeProvider theme={theme}>
                    <Switch
                      checked={item.active}
                      onChange={() => onActiveSwitch(item)}
                      color={'red'}
                      size="small"
                      sx={{
                        '& .MuiSwitch-track': {
                          height: 15,
                          backgroundColor: THEME_COLOR3 + ' !important',
                          marginTop: -0.3,
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor: '#999',
                        },
                        '& .Mui-checked .MuiSwitch-thumb': {
                          backgroundColor: 'red !important',
                        },
                      }}
                    />
                  </ThemeProvider>
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12} md={7}>
                {item.active == 1 ?
                  (<ThemeProvider theme={theme}>
                    <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Open"
                          value={item.open}
                          inputFormat="hh:mm a"
                          inputProps={{
                            autoComplete: 'none',
                            readOnly: true,
                            sx: {fontSize: 13, fontFamily: 'GilroySemiBold', marginTop: 0.7, marginBottom: 0.7},
                          }}
                          onChange={(val) => openCloseTime(val, item, 'open')}
                          renderInput={(params) =>
                            <TextField
                              focused
                              fullWidth
                              color="gray"
                              variant="standard"
                              InputLabelProps={{
                                style: {fontFamily: 'GilroyRegular'},
                              }}
                              sx={{
                                svg: {color: '#000', fontSize: 18, marginTop: 0.7, marginBottom: 0.7},
                                '& .MuiButtonBase-root': {
                                  width: 35,
                                  height: 35,
                                },
                              }}
                              {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Closed"
                          value={item.close}
                          inputFormat="hh:mm a"
                          inputProps={{
                            autoComplete: 'none',
                            readOnly: true,
                            sx: {fontSize: 13, fontFamily: 'GilroySemiBold', marginTop: 0.7, marginBottom: 0.7},
                          }}
                          onChange={(val) => openCloseTime(val, item, 'close')}
                          renderInput={(params) =>
                            <TextField
                              focused
                              fullWidth
                              color="gray"
                              variant="standard"
                              InputLabelProps={{
                                style: {fontFamily: 'GilroyRegular'},
                              }}
                              sx={{
                                svg: {color: '#000', fontSize: 18, marginTop: 0.7, marginBottom: 0.7},
                                '& .MuiButtonBase-root': {
                                  width: 35,
                                  height: 35,
                                },
                              }}
                              {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </ThemeProvider>)
                  : null}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <RestaurantBtn activeTab={2} loading={false} previousBtnClick={props.previousBtnClick} onSaveBtnClick={onSaveBtnClick}/>
    </Grid>

  );
};

export default AddRestaurantInfo;
