import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./AddCategory.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import AddSettingCategory from "../../components/restaurant/AddSettingCategory";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";

import { ReactComponent as BackIcon } from "../../assets/images/back.svg";

const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length >= 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [catData, setCatData] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {

    let params = location.pathname.split("/");
    if (params.length >= 5) {
      let catId = params[4];
      getCatData(catId);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getCatData = (catId) => {
    Api.getCategoryData(catId).then((response) => {
      if (response.success) {
        let data = response.data;
        let imageList = data.images.map((x) => {
          let index = x.lastIndexOf("/") + 1;
          let filename = x.substr(index);
          return {
            id: new Date(),
            name: filename,
            displayName: filename,
            size: 0,
            image: x,
            upload: true,
          };
        });
        data.images = imageList;
        setCatData(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Box className={styles.titleViewText} mb={2}>
            <Box
              className={styles.backBtn}
              onClick={() =>
                navigate("/restaurant/" + restaurantId + "/settings/category")
              }
            >
              <BackIcon width={30} height={30} />
            </Box>
            <Typography ml={1.5} className={styles.titleText}>
              Add Category
            </Typography>
          </Box>
          <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
            <Grid item xs={12} md={6} pb={2}>
              <AddSettingCategory
                catData={catData}
                onCancel={() =>
                  navigate("/restaurant/" + restaurantId + "/settings/category")
                }
                onSubmit={() =>
                  navigate("/restaurant/" + restaurantId + "/settings/category")
                }
                restaurantId={restaurantId}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default AddCategory;
