import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { connect } from "react-redux";

import Login from "./containers/Auth/Login";
import Logout from "./containers/Auth/Logout";
import Events from "./containers/Event/Events";
import AddEvent from "./containers/Event/AddEvent";
import Restaurants from "./containers/Restaurant/Restaurants";
import AddRestaurant from "./containers/Restaurant/AddRestaurantInfo";
import Users from "./containers/User/Users";
import RestaurantInfo from "./containers/Restaurant/RestaurantInfo";
import Settings from "./containers/Restaurant/Settings";
import SettingsMenu from "./containers/Restaurant/SettingsMenu";
import ManageMenu from "./containers/Restaurant/ManageMenu";
import ArModels from "./containers/Restaurant/ArModels";
import ArModelSelectMenu from "./containers/Restaurant/ArModelSelectMenu";
import AddArModel from "./containers/Restaurant/AddArModel";
import AddCategory from "./containers/Restaurant/AddCategory";
import AddMenu from "./containers/Restaurant/AddMenu";
import CategoryReorder from "./containers/Restaurant/CategoryReorder";
// import Menu from "./containers/Restaurant/Menu";
import RestaurantEvents from "./containers/Restaurant/Events";
import GiftCards from "./containers/Restaurant/GiftCards/GiftCards";
import GiftCardTransactions from "./containers/Restaurant/GiftCards/GiftCardTransactions";
import UberEats from "./containers/Restaurant/UberEats";
import AccountDetail from "./containers/Restaurant/AccountDetail";
import OrderHistory from "./containers/Restaurant/OrderHistory";
import Subscriptions from "./containers/Restaurant/Subscriptions";
import CustomRestaurantInfo from "./containers/Restaurant/CustomRestaurantInfo";
import SessionTimeout from "./components/SessionTimeout";
import Version from "./containers/Version";
import TableSetupSettings from "./containers/Restaurant/TableSetupSettings";

const App = (props) => {
  let routes = (
    <Routes>
      <Route exact path="/login" element={<Login {...props} />} />
      <Route exact path="/" element={<Navigate replace to="/login" />} />
      <Route path="/version" element={<Version />} />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );

  if (props.userToken) {
    routes = (
      <Routes>
        <Route exact path="/" element={<Events {...props} />} />
        <Route exact path="/add_event" element={<AddEvent {...props} />} />
        <Route exact path="/restaurants" element={<Restaurants {...props} />} />
        <Route
          exact
          path="/add_restaurant"
          element={<AddRestaurant {...props} />}
        />
        <Route exact path="/users" element={<Users {...props} />} />
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/restaurant/:id/menu"*/}
        {/*  element={<Menu {...props} />}*/}
        {/*/>*/}
        <Route
          exact
          path="/restaurant/:id/events"
          element={<RestaurantEvents {...props} />}
        />
        <Route exact path="/restaurant/:id" element={<Settings {...props} />} />
        <Route
          exact
          path="/restaurant/:id/settings"
          element={<SettingsMenu {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/settings/menu"
          element={<SettingsMenu {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/settings/category"
          element={<SettingsMenu {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/manage_menu"
          element={<ManageMenu {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/info/"
          element={<RestaurantInfo {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/custom_info/"
          element={<CustomRestaurantInfo {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/ar_models"
          element={<ArModels {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/select_menu"
          element={<ArModelSelectMenu {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/model/:id"
          element={<AddArModel {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/add_category"
          element={<AddCategory {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/add_category/:id"
          element={<AddCategory {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/add_menu"
          element={<AddMenu {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/add_menu/:id"
          element={<AddMenu {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/category_reorder"
          element={<CategoryReorder {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/uber_eats"
          element={<UberEats {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/swiggy"
          element={<UberEats {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/account_detail"
          element={<AccountDetail {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/order_history"
          element={<OrderHistory {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/gift_card"
          element={<GiftCards {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/gift_card/transactions"
          element={<GiftCardTransactions {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/subscriptions"
          element={<Subscriptions {...props} />}
        />
        <Route
          exact
          path="/restaurant/:id/table_setup_settings"
          element={<TableSetupSettings {...props} />}
        />
        <Route exact path="/logout" element={<Logout {...props} />} />
        <Route path="/version" element={<Version />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return (
    <BrowserRouter>
      <SessionTimeout userToken={props.userToken}>{routes}</SessionTimeout>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
  };
};

export default connect(mapStateToProps, null)(App);
