import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import * as Api from "../../api";

import Container from "../../components/Container";
import RestaurantList from "../../components/RestaurantList";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";

import { THEME_COLOR2 } from "../../constants";

import "../../assets/css/custom.css";

const pageSize = 20;

const Restaurants = () => {
  const [restaurantList, setRestaurantList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const navigate = useNavigate();

  useEffect(() => {
    getRestaurantList(1, "");
  }, []);

  const getRestaurantList = (pageNo, search) => {
    let filter = `?pageSize=${pageSize}&pageNum=${pageNo}`;
    if (search != "") {
      filter = `?pageSize=${pageSize}&pageNum=${pageNo}&search=${search}`;
    }
    setIsLoading(true);
    Api.restaurantList(filter).then((response) => {
      if (response.success) {
        setPageNum(pageNo);
        setSearchText(search);
        setTotalPages(response.data.pages);
        setRestaurantList(
          response.data.rows.map((x) => {
            return { ...x, selected: false };
          })
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onSelectPage = (value) => {
    getRestaurantList(value, searchText);
  };

  const onSearchRestaurant = (val) => {
    getRestaurantList(1, val);
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onSelectRestaurant = (data) => {
    let restaurant = [...restaurantList];
    restaurant.map((item, index) => {
      item["selected"] = false;
    });
    let restaurantIndex = restaurant.findIndex((item) => item._id === data._id);
    if (restaurantIndex >= 0) {
      restaurant[restaurantIndex]["selected"] =
        !restaurant[restaurantIndex]["selected"];
      setRestaurantList(restaurant);
    }
    // navigate('/restaurant/' + data._id);
    window.open(window.location.origin + "/restaurant/" + data._id, "_blank");
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={searchText}
      onSearch={onSearchRestaurant}
      searchLabel="Restaurants"
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          <Box sx={styles.titleMainBox}>
            <Typography variant={"h6"} sx={styles.restaurantText}>
              Restaurants
            </Typography>
            <Box
              sx={styles.addRestaurantBox}
              onClick={() => navigate("/add_restaurant")}
            >
              <Typography sx={styles.plusText}>+</Typography>
              <Typography sx={styles.addRestaurantText} ml={0.5}>
                Add New Restaurant
              </Typography>
            </Box>
          </Box>
          <Grid style={{ minHeight: window.innerHeight - 220 }}>
            <Grid container direction="row" mt={2}>
              {restaurantList.map((item, index) => {
                return (
                  <RestaurantList
                    key={index}
                    data={item}
                    onSelectRestaurant={onSelectRestaurant}
                  />
                );
              })}
            </Grid>
          </Grid>
          {totalPages > 0 ? (
            <Page
              totalPages={totalPages}
              pageNum={pageNum}
              onSelectPage={onSelectPage}
            />
          ) : null}
        </>
      )}
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Container>
  );
};

export default Restaurants;

const styles = {
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  restaurantText: {
    fontFamily: "GilroyBold",
  },
  addRestaurantBox: {
    backgroundColor: "#FF0000",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: '10px',
    px: 2,
  },
  plusText: {
    fontFamily: "GilroySemiBold",
    fontSize: 30,
    color: "#FFF",
  },
  addRestaurantText: {
    fontFamily: "GilroySemiBold",
    fontSize: 14,
    color: "#FFF",
  },
};
