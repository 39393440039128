import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./Subscriptions.module.css";

import Container from "../../components/Container";
import Loader from "../../components/Loader";

import { ReactComponent as BackIcon } from "../../assets/images/back.svg";

const Subscriptions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    let data = {
      name: "Starter",
      price: {
        value: 850,
        currency: "INR",
        _id: "64a4ec97487cdd2a04448d1e",
      },
      duration: "Quarterly",
      notes: "",
      status: 1,
      _id: "647478594ec0fe09ca7db8e7",
      currency: "AED",
      createdAt: "2023-05-29T10:03:05.691Z",
      updatedAt: "2023-05-29T10:03:05.691Z",
      __v: 0,
      features: [
        {
          _idSubscriptions: "647478594ec0fe09ca7db8e7",
          name: "Menu",
          notes: "",
          status: 1,
          _id: "6475b6024ec0fe09ca7de8ce",
          createdAt: "2023-05-30T08:38:26.121Z",
          updatedAt: "2023-05-30T08:38:26.121Z",
          __v: 0,
        },
        {
          _idSubscriptions: "647478594ec0fe09ca7db8e7",
          name: "Table Ordering",
          notes: "",
          status: 1,
          _id: "6475b60c4ec0fe09ca7de8d0",
          createdAt: "2023-05-30T08:38:36.663Z",
          updatedAt: "2023-05-30T08:38:36.663Z",
          __v: 0,
        },
        {
          _idSubscriptions: "647478594ec0fe09ca7db8e7",
          name: "Table Payment",
          notes: "",
          status: 1,
          _id: "6475b6144ec0fe09ca7de8da",
          createdAt: "2023-05-30T08:38:44.198Z",
          updatedAt: "2023-05-30T08:38:44.198Z",
          __v: 0,
        },
        {
          _idSubscriptions: "647478594ec0fe09ca7db8e7",
          name: "Online Ordering",
          notes: "",
          status: 1,
          _id: "6475b6204ec0fe09ca7de8e4",
          createdAt: "2023-05-30T08:38:56.852Z",
          updatedAt: "2023-05-30T08:38:56.852Z",
          __v: 0,
        },
        {
          _idSubscriptions: "647478594ec0fe09ca7db8e7",
          name: "Restaurant Mobile Manager",
          notes: "",
          status: 1,
          _id: "6475b62d4ec0fe09ca7de8ee",
          createdAt: "2023-05-30T08:39:09.837Z",
          updatedAt: "2023-05-30T08:39:09.837Z",
          __v: 0,
        },
        {
          _idSubscriptions: "647478594ec0fe09ca7db8e7",
          name: "Dashboard",
          notes: "",
          status: 1,
          _id: "6475b6424ec0fe09ca7de932",
          createdAt: "2023-05-30T08:39:30.328Z",
          updatedAt: "2023-05-30T08:39:30.328Z",
          __v: 0,
        },
        {
          _idSubscriptions: "647478594ec0fe09ca7db8e7",
          name: "Menu Display Style",
          notes: "",
          status: 1,
          _id: "6475b8cb4ec0fe09ca7de9c4",
          addOnFeatures: [
            {
              styleName: "style1",
              imageURL: require("../../assets/images/rest_img.png"),
            },
          ],
          createdAt: "2023-05-30T08:50:19.526Z",
          updatedAt: "2023-05-30T08:50:19.526Z",
          __v: 0,
        },
      ],
    };
    setSubscriptionData(data);
    setIsLoading(false);
  }, []);

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box className={styles.titleViewText} mb={2}>
              <Box
                className={styles.backBtn}
                onClick={() => navigate("/restaurant/" + restaurantId)}
              >
                <BackIcon width={30} height={30} />
              </Box>
              <Typography ml={1.5} className={styles.titleText}>
                Subscriptions
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
            <Grid container item xs={12} md={8}>
              <Box className={styles.subscriptionView} px={2}>
                <Box mt={2} className={styles.activePlanView}>
                  <Box className={styles.activePlan} py={0.8}>
                    ACTIVE PLAN
                  </Box>
                </Box>
                <Typography mt={2} className={styles.planTitle}>
                  {subscriptionData.name}
                </Typography>
                <Box className={styles.planPriceView}>
                  <Typography className={styles.planPrice}>
                    {subscriptionData.currency}
                    {subscriptionData.price.value}
                  </Typography>
                  <Typography ml={1} className={styles.planDuration}>
                    {subscriptionData.duration}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography className={styles.featureTitleText}>
                    Features:
                  </Typography>
                  {subscriptionData.features.map((x, i) => {
                    return (
                      <Box key={i} className={styles.featureView} py={1}>
                        <Typography className={styles.featureText}>
                          {x.name}
                        </Typography>
                        {x?.addOnFeatures?.length ? (
                          x?.addOnFeatures[0]?.styleName ? (
                            <img
                              src={x?.addOnFeatures[0]?.imageURL}
                              className={styles.menuDisplayImg}
                            />
                          ) : null
                        ) : null}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Subscriptions;
